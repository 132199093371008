import React from 'react';
import ReactDOM from 'react-dom/client';
import './customBootstrap.scss'
import './index.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import * as Sentry from '@sentry/browser';
import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import ReactGA from 'react-ga4';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

Sentry.init({ dsn: 'https://bd5775a6d28744e2b82e87102563226a@o367548.ingest.sentry.io/5425036' });

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'ja',
        ns: ['common'],
        defaultNS: 'common',
        load: 'languageOnly',
        backend: {
            loadPath: './i18n/{{lng}}.json',
            crossDomain: false
        }
    })
    .then(function (t) {
        ReactGA.initialize('UA-159920869-3');
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
        const root = ReactDOM.createRoot(
            document.getElementById('root') as HTMLElement
        );
        root.render(
            <React.StrictMode>
                <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY!}>
                    <App />
                </GoogleReCaptchaProvider>
            </React.StrictMode>
        );
    })
    .catch(function (error) {
        console.error('i18next initialization failed:', error);
    });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
