import {useTranslation} from "react-i18next";

const SectionActivities = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="row">
                <div className="offset-lg-2 col-lg-8 offset-sm-1 col-sm-10 col">
                    <p className="lead">{t('sectionActivities.main1')}</p>
                </div>
            </div>
            <div className="row">
                <div className="offset-lg-2 col-lg-8 offset-sm-1 col-sm-10 col">
                    <p className="lead">{t('sectionActivities.main2')}</p>
                </div>
            </div>
            <div className="row">
                <div className="offset-lg-2 col-lg-8 offset-sm-1 col-sm-10 col">
                    <p className="lead">{t('sectionActivities.main3')}</p>
                </div>
            </div>
        </>
    );
}

export default SectionActivities;