import React, {useState} from "react";
import brandIcon from '../images/brandicon.svg'
import {useTranslation} from "react-i18next";
import { Link } from 'react-scroll';

import {Section} from "../@types/section";
import {
    faBars,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Container, Nav, Navbar} from "react-bootstrap";

const AppNavbar = ({sections, isScrolled} : {sections: Section[], isScrolled: boolean}) => {
    const { t } = useTranslation();
    const mainNavRef = React.createRef<HTMLElement>();
    const [expanded, setExpanded] = useState<boolean>();
    const toggleMenu = () => {
        setExpanded(!expanded);
        if (!isScrolled) {
            mainNavRef.current?.classList.toggle("bg-dark");
        }
    }
    return (
        <Navbar expanded={expanded} expand="lg" fixed="top" variant="dark" className={`${isScrolled ? "bg-dark" : "navbar-extended"} `} id="mainNav" ref={mainNavRef}>
            <Container fluid>
                <a className="navbar-brand d-flex flex-body" href="#root">
                    <img src={brandIcon} width="50" height="50" className="d-inline-block flex-row" alt="brand icon"
                         loading="lazy" />
                    <span className="d-flex flex-column ml-2">
                    <span>{t('nav.brandName')}</span>
                    <span className="small">{t('nav.brandLocation')}</span>
                </span>
                </a>
                <button
                    className="navbar-toggler navbar-toggler-right text-uppercase fw-bold bg-primary text-white rounded"
                    type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive"
                    aria-expanded="false" aria-label="Toggle navigation" onClick={toggleMenu}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <Navbar.Collapse className="flex-column" id="navbarResponsive">
                    <Nav>
                        {sections.map((section, i) => (
                            <li key={i} className='nav-item'>
                                <Link
                                    activeClass='active'
                                    spy={true}
                                    key={section.name}
                                    to={section.name}
                                    className='nav-link rounded'
                                    smooth={true}
                                    onClick={() => setExpanded(false)}
                                >
                                    {t('section.' + section.name)}
                                </Link>
                            </li>
                        ))
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default AppNavbar;