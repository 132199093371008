import {Trans, useTranslation} from "react-i18next";
import React from "react";

const SectionSchedule = () => {
    const {t} = useTranslation();

    return (
        <div className="row">
            <div className="offset-xl-2 col-xl-8 offset-sm-1 col-sm-10 col">
                <div className="card text-dark">
                    <div className="card-body">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <span>{t('sectionSchedule.usual.info1')}</span>
                                <br/>
                                <span className="text-muted small">{t('sectionSchedule.usual.info1_detail')}</span></li>
                            <li className="list-group-item">{t('sectionSchedule.usual.info2')}</li>
                            <li className="list-group-item">{t('sectionSchedule.usual.info3')}</li>
                            <li className="list-group-item">
                                <Trans
                                    i18nKey='sectionSchedule.usual.info4'
                                    components={{contactLink: <a href='#contact'>Contact</a>}}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionSchedule;