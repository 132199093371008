import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import axios from "axios";
import {useState} from "react";
import {toast} from "react-toastify";
import {GoogleReCaptcha} from "react-google-recaptcha-v3";

type ContactFormValues = {
    name: string;
    email: string;
    contactType: string;
    message: string;
};

const SectionContact = () => {
    const {t} = useTranslation();
    const [sendButtonDisabled, setSendButtonDisabled] = useState<boolean>(false);
    const { register, handleSubmit, formState: { errors }, reset } = useForm<ContactFormValues>();
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

    const onSubmit = handleSubmit((data) => {
        if (!recaptchaToken) {
            toast.error(t("sectionContact.form.recaptcha.error"));
            return;
        }

        setSendButtonDisabled(true);
        axios.post(process.env.REACT_APP_MAIL_ENDPOINT!, data)
            .then(() => {
                toast.success(t("sectionContact.form.send.success"));
                reset();
            })
            .catch(() => toast.error(t("sectionContact.form.send.error", { name: data.name })))
            .finally(() => {
                setSendButtonDisabled(false);
            })
    });

    const handleRecaptchaVerify = (token: string) => {
        setRecaptchaToken(token);
    };

    return (
        <div className="row">
            <div className="col-lg-8 mx-auto">
                <form id="contactForm" onSubmit={onSubmit} noValidate={true}>
                    <div className="control-group">
                        <div className="form-group mb-0 pb-2">
                            <label htmlFor="name">{t('sectionContact.form.name.label')}</label>
                            <input {...register("name", {required: {value: true, message: t('sectionContact.form.name.error')}})}
                                   className={`form-control ${errors.name ? "is-invalid" : ""}`}
                                   id="name" type="text" placeholder={t('sectionContact.form.name.placeholder').toString()}/>
                            {errors?.name && <div className="invalid-feedback">{errors.name.message}</div>}

                        </div>
                    </div>
                    <div className="control-group">
                        <div className="form-group mb-0 pb-2">
                            <label htmlFor="email">{t('sectionContact.form.email.label')}</label>
                            <input {...register('email', {
                                required: {value: true, message: t('sectionContact.form.email.required')},
                                pattern: { value: /\S+@\S+\.\S+/, message: t('sectionContact.form.email.invalid') }})}
                                   className={`form-control ${errors.email ? "is-invalid" : ""}`} type="email" placeholder={t('sectionContact.form.email.placeholder').toString()}/>
                            {errors?.email && <div className="invalid-feedback">{errors.email.message}</div>}
                        </div>
                    </div>
                    <div className="control-group">
                        <div className="form-group mb-0 pb-2">
                            <label htmlFor="contactType">{t('sectionContact.form.type.interest')}</label>
                            <select {...register('contactType', { required: {value:true, message: t('sectionContact.form.type.error') }})}
                                    className={`form-control ${errors.contactType ? "is-invalid" : ""}`}>
                                <option value=""></option>
                                <option value="trial">{t('sectionContact.form.type.trial')}</option>
                                <option value="member">{t('sectionContact.form.type.member')}</option>
                                <option value="others">{t('sectionContact.form.type.others')}</option>
                            </select>
                            {errors?.contactType && <div className="invalid-feedback">{errors.contactType.message}</div>}
                        </div>
                    </div>
                    <div className="control-group">
                        <div className="form-group mb-0 pb-2">
                            <label htmlFor="message">{t('sectionContact.form.message.label')}</label>
                            <textarea {...register("message", { required: { value: true, message: t('sectionContact.form.message.error') }})}
                                      className={`form-control ${errors.message ? "is-invalid" : ""}`} id="message" rows={5} placeholder={t('sectionContact.form.message.placeholder').toString()}></textarea>
                            {errors?.message && <div className="invalid-feedback">{errors.message.message}</div>}
                        </div>
                    </div>
                    <br/>
                    <div id="success"></div>
                    <div className="form-group">
                        <GoogleReCaptcha onVerify={handleRecaptchaVerify} />
                        <button id="sendMessageButton" type="submit" disabled={sendButtonDisabled}
                                className="btn btn-primary btn-xl">{t('sectionContact.form.send.button')}</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SectionContact;