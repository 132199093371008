import {useTranslation} from "react-i18next";
import satoImage from '../images/teachers/satoumatsuyoshi.jpg';
import kumikoImage from '../images/teachers/kanazawakumiko.jpg';
import akikoImage from '../images/teachers/hongouakiko.jpg'

const SectionTeachers = () => {
    const {t} = useTranslation();

    return (
        <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="col">
                <div className="card">
                    <img src={satoImage} className="card-img-top" alt="satou matsuyoshi"
                         loading="lazy"/>
                    <div className="card-header lead fw-bold">{t('sectionTeachers.satou.name')}</div>
                    <div className="card-body">
                        <p className="card-text">{t('sectionTeachers.satou.info1')}</p>
                        <p className="card-text">{t('sectionTeachers.satou.info2')}</p>
                        <p className="card-text">{t('sectionTeachers.satou.info3')}</p>
                        <p className="card-text">{t('sectionTeachers.satou.info4')}</p>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card">
                    <img src={kumikoImage} className="card-img-top" alt="kanazawa kumiko"
                         loading="lazy"/>
                    <div className="card-header lead fw-bold">{t('sectionTeachers.kanazawa.name')}</div>
                    <div className="card-body">
                        <p className="card-text">{t('sectionTeachers.kanazawa.info1')}</p>
                        <p>{t('sectionTeachers.kanazawa.info2')}</p>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card">
                    <img src={akikoImage} className="card-img-top" alt="hongou akiko"
                         loading="lazy"/>
                    <div className="card-header lead fw-bold">{t('sectionTeachers.hongou.name')}</div>
                    <div className="card-body">
                        <p className="card-text">{t('sectionTeachers.hongou.info1')}</p>
                        <p className="card-text">{t('sectionTeachers.hongou.info2')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionTeachers;