import React from "react";
import {
    faStar
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Trans, useTranslation} from "react-i18next";
import {Section} from "../@types/section";

const SectionPartial = ({section, isEven, children}: {section: Section, isEven: boolean, children: React.ReactElement}) => {
    const { t } = useTranslation();
    return (
        <div className={`page-section ${isEven ? "mb-0" : "bg-primary text-white"}`} id={section.name}>
            <div className="container-fluid">
                <div className="p-5 mb-4 bg-light rounded-3">
                    <h1 className="display-3 text-dark text-center">{t("section." + section.name )}</h1>
                    <div className="divider-custom">
                        <div className="divider-custom-line"></div>
                        <div className="divider-custom-icon"><FontAwesomeIcon icon={faStar} /></div>
                        <div className="divider-custom-line"></div>
                    </div>
                    {section.subHeader && <p className="lead text-dark"><Trans i18nKey={section.subHeader.i18nKey} components={section.subHeader.component} /></p>}
                </div>
                {children}
            </div>
        </div>
    );
}

export default SectionPartial;