import {useTranslation} from "react-i18next";
import joyOfYogaImage from '../images/others/theJoyOfYoga_Book.jpg';

const SectionOthers = () => {
    const {t} = useTranslation();
    
    return (
        <div className="row row-cols-1 row-cols-md-3 g-4">
            <div className="card text-dark">
                <img src={joyOfYogaImage} className="card-img-top" alt="the joy of yoga" loading="lazy"/>
                <div className="card-body">
                    <h5 className="card-title">{t('sectionOthers.joyOfYogaBook.title')}</h5>
                    <p className="card-text">{t('sectionOthers.joyOfYogaBook.info')}</p>
                    <a href="https://amzn.to/31179qA"
                       className="card-link">{t('sectionOthers.joyOfYogaBook.link')}</a>
                </div>
            </div>
        </div>
    );
}

export default SectionOthers;