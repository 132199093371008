import React from "react";
import {
    faFacebookF,
    faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => {
    return (
        <footer className="footer text-center">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <h4 className="mb-4">
                            <small>Copyright &copy;<a href="https://enchan.org">EnChan</a> {new Date().getFullYear()}</small>
                        </h4>
                    </div>
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <a className="btn btn-outline-light btn-social mx-1" href="https://www.facebook.com/hinode.matsukaze/">
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a className="btn btn-outline-light btn-social mx-1" href="https://www.instagram.com/hinodeyoga/">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </div>

                    <div className="col-lg-4"></div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;