import {useTranslation} from "react-i18next";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

//todo subheader contains href but unencoded, find a solution to make it work with react-i18n
const SectionMembership = () => {
    const {t} = useTranslation();

    return (
        <>
            <div className="row pb-2">
                <div className="col">
                    <div className="card text-dark">
                        <div className="card-header lead fw-bold">{t('sectionMembership.test.name')}</div>
                        <div className="card-body">
                            <p className="card-text">{t('sectionMembership.test.description')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pb-2">
                <div className="col">
                    <div className="card text-dark">
                        <div className="card-header lead fw-bold">{t('sectionMembership.member.name')}</div>
                        <div className="card-body">
                            <p className="card-text">{t('sectionMembership.member.description')}</p>
                        </div>
                        <table className="table table-striped">
                            <tbody>
                            <tr>
                                <td>{t('sectionMembership.member.regular.name')}</td>
                                <td>{t('sectionMembership.member.regular.description')}</td>
                                <td>{t('sectionMembership.member.regular.price')}</td>
                            </tr>
                            <tr>
                                <td>{t('sectionMembership.member.annual.name')}</td>
                                <td>{t('sectionMembership.member.annual.description')}</td>
                                <td>{t('sectionMembership.member.annual.price')}</td>
                            </tr>
                            <tr>
                                <td colSpan={3} className="text-center">{t('sectionMembership.member.entry.name')}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{t('sectionMembership.member.entry.live.description')}</td>
                                <td>{t('sectionMembership.member.entry.live.price')}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>{t('sectionMembership.member.entry.online.description')}</td>
                                <td>{t('sectionMembership.member.entry.online.price')}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="card text-dark">
                        <div className="card-header lead fw-bold">{t('sectionMembership.online.name')}</div>
                        <div className="card-body">
                            <p className="card-text">{t('sectionMembership.online.description')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center mt-4">
                <a className="btn btn-xl btn-outline-light" target="_blank" rel="noreferrer"
                   href="/downloads/日の出ヨガ会則　20230611改定.pdf">
                    <FontAwesomeIcon icon={faDownload}/> <span>{t('sectionMembership.constitution_download')}</span>
                </a>
            </div>
        </>
    );
}

export default SectionMembership;