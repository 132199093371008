import {useTranslation} from "react-i18next";

const SectionLocation = () => {
    const {t} = useTranslation();

    return (
        <div className="row">
            <div className="col-md-4 col-sm-12">
                <div className="list-group" id="locations-tab" role="tablist">
                    <a className="list-group-item list-group-item-action list-group-item-secondary active"
                       id="location-main-list" data-bs-toggle="list" href="#list-main" role="tab"
                       aria-controls="main">
                        {t('sectionLocation.main.name')}
                    </a>
                    <a className="list-group-item list-group-item-action list-group-item-secondary"
                       id="location-secondary-list" data-bs-toggle="list" href="#list-secondary" role="tab"
                       aria-controls="secondary">
                        {t('sectionLocation.secondary.name')}
                    </a>
                </div>
            </div>
            <div className="col-md-6 col-sm-12">
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="list-main" role="tabpanel" aria-labelledby="list-main">
                        <div className="card text-dark">
                            <div className="card-header">{t('sectionLocation.main.name')}</div>
                            <div className="card-body">
                                <h5 className="card-title">{t('sectionLocation.main.room')}</h5>
                                <p className="card-text">{t('sectionLocation.main.address')}</p>
                                <iframe title="location1"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.337645145139!2d139.92157931525765!3d35.64405198020295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzXCsDM4JzM4LjYiTiAxMznCsDU1JzI1LjYiRQ!5e0!3m2!1sen!2sjp!4v1598051634755!5m2!1sen!2sjp"
                                    loading="lazy" width="100%" height="100%" frameBorder="0" style={{ border:0 }}
                                    allowFullScreen={true} aria-hidden="false" tabIndex={0}></iframe>
                            </div>
                            <div className="card-body">
                                <a href="http://www.city.urayasu.lg.jp/shisetsu/bunka/kouminkan/1005648.html"
                                   className="card-link">
                                    {t('sectionLocation.main.goToLink')}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="list-secondary" role="tabpanel" aria-labelledby="list-secondary">
                        <div className="card text-dark">
                            <div className="card-header">{t('sectionLocation.secondary.name')}</div>
                            <div className="card-body">
                                <h5 className="card-title">{t('sectionLocation.secondary.room')}</h5>
                                <p className="card-text">{t('sectionLocation.main.address')}</p>
                                <iframe title="location2"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9210.93984974463!2d139.91209378305422!3d35.63681366883202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzXCsDM4JzExLjgiTiAxMznCsDU1JzAzLjQiRQ!5e0!3m2!1sen!2sjp!4v1598052039921!5m2!1sen!2sjp"
                                    loading="lazy" width="100%" height="100%" frameBorder="0" style={{ border:0 }}
                                    allowFullScreen={true} aria-hidden="false" tabIndex={0}></iframe>
                            </div>
                            <div className="card-body">
                                <a href="http://www.city.urayasu.lg.jp/shisetsu/bunka/kouminkan/1005645.html"
                                   className="card-link">
                                    {t('sectionLocation.secondary.goToLink')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionLocation;