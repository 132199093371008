import sliders from '../data/slider.json'
import {useTranslation} from "react-i18next";

const SectionInformation = () => {
    const {t} = useTranslation();

    return (
        <div className="row">
            <div className="col-md-6">
                <div className="fb-page" data-href="https://www.facebook.com/hinode.matsukaze/" data-tabs="timeline"
                     data-width="" data-height="" data-small-header="false" data-adapt-container-width="true"
                     data-hide-cover="false" data-show-facepile="true">
                    <blockquote cite="https://www.facebook.com/hinode.matsukaze/" className="fb-xfbml-parse-ignore">
                        <a href="https://www.facebook.com/hinode.matsukaze/">{t('sectionInformation.facebookName')}</a>
                    </blockquote>
                </div>
            </div>
            <div className="col-md-6">
                <div id="sliderContainer" className="carousel slide" data-bs-ride="carousel">
                    <div id="sliderNav" className="carousel-indicators">
                        {sliders.map((slider, i) => (
                            <button key={i} data-bs-target='#sliderContainer' data-bs-slide-to={i}
                                className={i === 0 ? "active" : ""}/>
                        ))}
                    </div>
                    <div id="sliderContent" className="carousel-inner">
                        {sliders.map((slider, i) => (
                            <div key={i} className={`carousel-item ${i === 0 ? "active" : ""}`}>
                                <img src={require(`../images/sliders/${slider.fileName}.jpg`)} loading='lazy'
                                     className='d-block w-100' alt={t(`slider.${slider.fileName}.text`).toString()}/>
                                <div className='carousel-caption d-none d-sm-block bg-dark mb-4'>
                                    <h5>{t(`slider.${slider.fileName}.header`)}</h5>
                                    <p>{t(`slider.${slider.fileName}.text`)}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#sliderContainer" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">{t('slider.previous')}</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#sliderContainer" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">{t('slider.next')}</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SectionInformation;