import React, {useEffect, useState} from 'react';
import AppNavbar from "./components/AppNavbar";
import Footer from "./components/Footer";
import SectionPartial from './components/SectionPartial';
import SectionInformation from "./sections/SectionInformation";
import SectionActivities from "./sections/SectionActivities";
import {Section} from "./@types/section";
import SectionTeachers from "./sections/SectionTeachers";
import SectionMembership from "./sections/SectionMembership";
import SectionSchedule from "./sections/SectionSchedule";
import SectionLocation from "./sections/SectionLocation";
import SectionFaq from "./sections/SectionFaq";
import SectionOthers from "./sections/SectionOthers";
import SectionContact from "./sections/SectionContact";
import {ToastContainer} from "react-toastify";

const App = () => {
    const sections: Section[] = [
        {name: 'information', subHeader: null, element: <SectionInformation />},
        {name: 'activities', subHeader: {i18nKey: 'sectionActivities.subHeader'}, element: <SectionActivities />},
        {name: 'teachers', subHeader: {i18nKey: 'sectionTeachers.subHeader'}, element: <SectionTeachers />},
        {name: 'membership', subHeader: {i18nKey: 'sectionMembership.subHeader', component: {contactLink: <a href='#contact'>Contact</a>}}, element: <SectionMembership />},
        {name: 'schedule', subHeader: {i18nKey: 'sectionSchedule.subHeader'}, element: <SectionSchedule />},
        {name: 'location', subHeader: {i18nKey: 'sectionLocation.subHeader'}, element: <SectionLocation />},
        {name: 'faq', subHeader: {i18nKey: 'sectionFaq.subHeader'}, element: <SectionFaq />},
        {name: 'others', subHeader: {i18nKey: 'sectionOthers.subHeader'}, element: <SectionOthers />},
        {name: 'contact', subHeader: {i18nKey: 'sectionContact.subHeader'}, element: <SectionContact />}
    ];

    const [isScrolled, setIsScrolled] = useState(false);

    const changeNavAppearance = () => {
        const top = window.scrollY || document.documentElement.scrollTop;
        setIsScrolled(top > 100);
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNavAppearance)
        changeNavAppearance();
    });

    return (
        <>
            <ToastContainer position="top-center" theme="colored" />
            <div id="fb-root"></div>
            <header>
                <AppNavbar sections={sections} isScrolled={isScrolled}/>
            </header>
            <main className="masthead">
                <div className="container-fluid"></div>
            </main>
            {sections.map((section, i) => (
                <SectionPartial key={i} section={section} isEven={i % 2 === 0}>
                    {section.element}
                </SectionPartial>
            ))}
            <Footer/>
        </>)
}

export default App;
