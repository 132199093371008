import {useTranslation} from "react-i18next";

const SectionFaq = () => {
    const {t} = useTranslation();

    return (
        <div className="accordion" id="faq">
            <div className="card">
                <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                        <button className="btn btn-link btn-block text-left"
                                type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                aria-expanded="true" aria-controls="collapseOne">
                            {t('sectionFaq.1.q')}
                        </button>
                    </h2>
                </div>

                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#faq">
                    <div className="card-body text-dark">
                        {t('sectionFaq.1.a')}
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header" id="headingTwo">
                    <h2 className="mb-0">
                        <button
                                className="btn btn-link btn-block text-left collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                                aria-controls="collapseTwo">
                            {t('sectionFaq.2.q')}
                        </button>
                    </h2>
                </div>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#faq">
                    <div className="card-body text-dark">
                        {t('sectionFaq.2.a')}
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header" id="headingThree">
                    <h2 className="mb-0">
                        <button
                                className="btn btn-link btn-block text-left collapsed" type="button"
                                data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                                aria-controls="collapseThree">
                            {t('sectionFaq.3.q')}
                        </button>
                    </h2>
                </div>
                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#faq">
                    <div className="card-body text-dark">
                        {t('sectionFaq.3.a')}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionFaq;